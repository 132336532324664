/**
 *  @file       SwitchButton.js
 *  @brief      This component is used to switch between music and sports events.
 *  @details    It is used in the header of the application.
 * */

import React, {useContext, useEffect, useRef} from 'react';
import {eventsContext} from "../Utils/eventContext";

function SwitchButton() {
    const {eventType, setEventType} = useContext(eventsContext);
    const ref = useRef();

    useEffect(() => {
        ref.current.checked = !eventType;
    }, [])

    return (
        <div className={'w-fit rounded-md flex flex-row text-white text-lg px-3'}>
            <label htmlFor="Toggle1" className="inline-flex items-center space-x-4 cursor-pointer text-gray-100" >
                <span>Music</span>
                <span className="relative">
                    <input ref={ref} id="Toggle1" type="checkbox" className="hidden peer" onClick={() => {setEventType(!eventType)}}/>
                    <div className="w-10 h-6 rounded-full shadow-inner bg-primary peer-checked:bg-blue-500"></div>
                    <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
                </span>
                <span>Sports</span>
            </label>
        </div>
    );
}

export default SwitchButton;

