import React from 'react';
import Hero from "../images/HeroImage2.jpeg";
import StyledNavbar from "../Components/StyledNavbar";
import Footer from "../Components/Footer";
import logo from "../images/logo_medium.png";
import {Link} from "react-router-dom";

function SuspensePage() {
    return (
        <div
            style={{
                backgroundImage: `url(${Hero})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                minHeight: '60vh',
                minWidth: '100%',
            }}
            className="bg-no-repeat bg-cover bg-center w-full h-full">
            <StyledNavbar />
            <section>
                <div className="hero_title flex flex-col w-full justify-center items-center gap-y-12 min-h-screen ">
                    {/*<h1 className="text-[8rem] lg:text-[12rem] font-extrabold text-white drop-shadow-bright font-bahnschrift lg:font-bahnschrift md:font-bahnschrift sm:font-bahnschrift animate-fadeIn">koble.</h1>*/}
                    <img src={logo} className={'h-24 lg:h-36 animate-fadeIn'} alt={'koble logo'}/>
                    <hr className="w-[60px] border-t-primary border-t-2 rounded " />
                    <p className="text-white font-semibold text-xl font-sans">Connecting fans with bands</p>
                    <Link to={'/about'}>
                        <button className="btn-red w-fit rounded-lg">LEARN MORE</button>
                    </Link>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default SuspensePage;
