
import {Float, PresentationControls, useGLTF} from "@react-three/drei";
// import {useControls} from "leva";

export function Phone() {

    // const pos = useControls({
    //     x: 1,
    //     y: -2,
    //     z: 1,
    // })
    const phone = useGLTF('Iphone12.glb');
    return (
        <>
            <PresentationControls
                position={ [0, 0, 10] }
                rotation={ [0, -1.5, 0] }
                polar={[-0.4, 0.2]}
                azimuth={[-1,0.75]}
                config={ { mass: 2, tension: 400 } }
                snap={ { mass: 4, tension: 400 } }
            >
                <Float>
                    <primitive object={ phone.scene } position={[1.5, -2, 1]}/>
                </Float>
            </PresentationControls>
        </>
    );
}

