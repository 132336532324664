import KEYS from "../keys";
import {format} from "date-fns";
import {object} from "prop-types";


// Todo Used to turn on/off console logs.
export const DEBUG = false;



// These sized are shared between the components across the app to make it responsive.
export const sizes = {
    small: 640,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1536, // 2xl The size of a 15" laptop. If size is below this, the app will be in mobile mode.
}

/**
 *  Builds a query string given location and event type.
 * <br/>
 *  Ex: loc: {<br/>
 *     "Lat": 52.1119,<br/>
 *     "Lng": -106.7297,<br/>
 *     "City": "Saskatoon",<br/>
 *     "State": "Saskatchewan"<br/>
 *      },<br/>
 *      EventType: true <br/>
 *      => apikey=123&classificationName=music&latlong=52.1119,-106.7297&city=Saskatoon
 *
 *  @param {object} loc location object.
 *  @param {boolean} eventType true if music, false if sports.
 *  @returns {string} query string.
 * */
export const BuildURL = (loc, eventType) => {
    let query = encodeQueryData({
        apikey: KEYS.ticketmaster,
        classificationName: eventType ? "music" : "sports",
        latlong: loc?.Lat && loc?.Lat + "," + loc?.Lng,
        city: loc?.City,
        sort: "date,asc",
    })
    return "https://app.ticketmaster.com/discovery/v2/events.json?" + query;
}

let device = false

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    // true for mobile device
    console.log("mobile device");
    device = true
} else {
    // false for not mobile device
    console.log("not mobile device");
    device = false
}


/**
 * Takes a list of objects and returns the url of the first image that has a ratio of 16_9 and a width of 600 or more.
 * If no image is found, it will return the url of the first image.
 * @param {object[]} images list of objects.
 * @returns {string} url of the image.
 * */
export function filterByImageRatio(images) {
    for (let i = 0; i < images.length; i++) {
        if (images[i].ratio === "16_9" && images[i].width >= 600) {
            return images[i].url;
        }
    }
}

/**
 * Join the url and the query string.
 * Ex: {apikey: "123", source: "ticketmaster"} => apikey=123&source=ticketmaster
 * @param {object} data key value pairs.
 * @returns {string} url with query string
 */
export function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        if (data[d] !== '' && data[d] !== null && data[d] !== undefined && data[d] !== false){
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
    return ret.join('&');
}


/**
 * Formats the date for TicketMaster API. If no date is given, it will use the current date.
 * @param DateEntry type Date
 * @returns {string} Ex: 2021-09-25T00:00:00Z
 */
export function formatDateDisplay(DateEntry) {
    return format(DateEntry || new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
}

export const diff = (diffMe, diffBy) => diffMe.split(diffBy).join('')  // if days are different.

/**
 * Checks if the object is empty.
 * @param {object} obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
    for(const prop in obj) {
        if(Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    } return JSON.stringify(obj) === JSON.stringify({});
}


/**
 * @param {object} events - array of events, ticketmaster response. (result._embedded.events)
 * @param {object} oldEvents - Key value pair, where the key is the event id.
 * @returns {object} - Key value pair, where the key is the event id.
 * */
export function eventsById(events, oldEvents = {}) {
    let uniqueEvents = {};
    let combined = {...events, ...oldEvents};
    for (let i = 0; i < Object.keys(combined).length; i++) {
        let event = combined[i];
        if (event?.id) uniqueEvents[event?.id] = event;
    } return uniqueEvents;
}



