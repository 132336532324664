import React, { useState, useEffect, useRef, useContext, Suspense } from 'react';
import Card from "../Card/Card";
import { motion } from "framer-motion"
import { eventsContext } from "../../Utils/eventContext";
import { DEBUG, filterByImageRatio } from "../../Utils/Utils";

/**
 * @class Carousel
 * @desc Component that displays a horizon scrollable carousel of events, each in a Card component.
 * Will wait for events to be loaded before displaying.
 * @precondition  Needs events to be loaded in from localStorage or API.
 * */
export default function Carousel() {

    const [width, setWidth] = useState(0);
    const carousel = useRef();
    const {events, loading} = useContext(eventsContext);

    const [suggestions, setSuggestions] = useState(null);

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
    }, [suggestions])

    useEffect(() => {
        try {
            const arr = Object.values(events)
            const e = localStorage.getItem("suggestions")?.length > 0 && JSON.parse(localStorage.getItem("suggestions"))
            if (arr.length > 0){
                setSuggestions(arr)
            } else {
                setSuggestions(Object.values(e))
            }
        } catch (e) {
            console.log(e)
        }
    }, [events])

    if (DEBUG) console.log('Carousel: suggestions: ', suggestions)

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <motion.div ref={carousel} className={'bg-transparent cursor-grab overflow-hidden my-4 lg:mx-4'}>
                {(loading && suggestions.length === 0) && <h1 className={'text-2xl lg:text-4xl font-bold text-white my-8 mx-4'}>Finding Events...</h1>}
                {suggestions && <h1 className={'text-2xl lg:text-4xl font-bold text-white my-8 mx-4'}>Events Near You: </h1>}
                <motion.div drag="x" dragConstraints={{right: 0, left: -width}} className={'flex gap-4'}>
                    {suggestions && suggestions.map((event, index) => {
                        return (
                            <Card
                                image={filterByImageRatio(event?.images)}
                                title={event?.name}
                                date={event?.dates?.start?.localDate}
                                address={event?._embedded?.venues[0]?.name + ", " + event?._embedded?.venues[0]?.city?.name + ", " + event?._embedded?.venues[0]?.state?.name}
                                url={event?.url}
                                id={event?.id}
                                key={event?.id}
                            />
                        )})}
                </motion.div>
            </motion.div>
        </Suspense>
    );
}
