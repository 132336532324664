import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png'
import twitter from '../images/twitter.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar as CalendarIcon, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import {format} from "date-fns";
import { eventsContext } from "../Utils/eventContext";
import {useContext, useEffect, useState} from "react";
import Accordion from "../Components/Accordion/Accordion";
import ErrorBoundary from "../Components/errorBoundary";
import KEYS from "../keys";
import {DEBUG} from "../Utils/Utils";
import {Page404} from "./Page404";


function filterByImageRatio(images) {
    for (let i = 0; i < images.length; i++) {
        if (images[i].ratio === "16_9" && images[i].width >= 1000) {
            return images[i].url;
        }
    }
}


export default function Details() {

    const { id } = useParams();
    const { events } = useContext(eventsContext);
    const [loading, setLoading] = useState(false);
    const suggestedEvents = localStorage.getItem('suggestions')
    const [event, setEvent] = useState(events[id])


    useEffect(() => {
        if (!event) {
            if (suggestedEvents) {
                const suggestedEvents = JSON.parse(localStorage.getItem('suggestions'));
                const e = suggestedEvents[id]
                // event = e;
                setEvent(e)
                if (DEBUG) console.log('Details: event: suggestedEvents: e:', event, event?.length > 0)
            }
        }
        if (event?.length === 0 && !loading) {
            const url = `https://app.ticketmaster.com/discovery/v2/events/${id}.json?apikey=${KEYS.ticketmaster}`
            if (DEBUG) console.log('Details: event: url:', url)
            setLoading(true)
            console.log('Details: event: loading:', loading)
            fetch(url)
                .then(res => res.json())
                .then((result) => {
                    if (DEBUG) console.log('Details: event: result:', result)
                    // event = result
                    setEvent(result)
                    if (DEBUG) console.log(event)
                    if (event) setLoading(false)
                })
        }
    }, [])

    const title = event?.name;
    document.title = "Koble - " + title;

    return (
        <ErrorBoundary fallback={<Page404 />}>
            {event && <DetailsPage event={event}/>}
        </ErrorBoundary>
  );
}


function DetailsPage(props){
    const event = props.event;
    console.log('DetailsPage: event:', event)
    const image = event?.images ? filterByImageRatio(event.images) : null;

    let formattedDate = null;
    if(event?.dates?.start?.localDate){
        const eventDate = new Date(event?.dates?.start?.localDate);
        formattedDate = format(eventDate, 'eee MMMM d, yyyy h:mm a');
    }

    const streetAddress = event?._embedded?.venues[0]?.address?.line1;
    const address = event?._embedded?.venues[0]?.name;
    const city = event?._embedded?.venues[0]?.city?.name;
    const state = event?._embedded?.venues[0]?.state?.stateCode;
    return (
        <>
            <div className="body flex flex-col w-full h-full flex-col items-center justify-center place-content-center bg-secondary text-white py-10 antialiased md:subpixel-antialiased gap-y-16">
                <div className="top flex flex-col items-center justify-center place-content-center max-w-[1200px] gap-4 px-4">
                    <img className='object-cover rounded-3xl shadow-2xl shadow-purpleNight w-full max-h-[800px] max-w-[1200px]' src={image} alt=""/>
                </div>
                <div className='heroDetails w-full max-w-[1200px] flex flex-col justify-between font-medium font-sans tracking-tight text-lg px-4 drop-shadow gap-4'>
                    {event && (event?.title?.toLowerCase().search('postponed') > 0 || event?.title?.toLowerCase().search('rescheduled') > 0) ?
                        <h2 className="font-bold text-xl font-sans text-primary">{event.title || event?.name}</h2>:
                        <h2 className="font-bold font-sans">{event.title || event?.name}</h2>}

                    <div className="flex flex-row items-baseline align-middle gap-2">
                        <FontAwesomeIcon icon={faLocationDot} className={"location-button text-white"}/>
                        <h3>{address + ", " + streetAddress + ", " + city + ", " +state}</h3>
                    </div>
                    <div className="flex flex-row gap-2 items-baseline">
                        <FontAwesomeIcon icon={CalendarIcon} className="text-white" />
                        <h3>{formattedDate}</h3>
                    </div>
                </div>
                <div className="bottom flex flex-col lg:flex-row gap-10 w-full max-w-[1200px] h-full px-4">
                    <BottomLeft event={event}/>
                    <BottomRight event={event}/>
                </div>
            </div>
        </>
    );
}


const BottomRight = (props) => {
    const {event} = props;
    let facebookUrl;
    let instagramUrl;
    let twitterUrl ;

    if (event?._embedded?.hasOwnProperty('attractions')) {
        if(event?._embedded?.attractions[0]?.hasOwnProperty('externalLinks')) {
            facebookUrl = event?._embedded?.attractions[0]?.externalLinks?.hasOwnProperty('facebook') ? event._embedded.attractions[0].externalLinks.facebook[0].url : null;
            instagramUrl = event?._embedded?.attractions[0]?.externalLinks?.hasOwnProperty('instagram') ? event._embedded.attractions[0].externalLinks.instagram[0].url : null;
            twitterUrl = event?._embedded?.attractions[0]?.externalLinks?.hasOwnProperty('twitter') ? event._embedded.attractions[0].externalLinks.twitter[0].url : null;
        }
    }

    return (
        <div className="bottomRight flex flex-col">
            <div className="getTicketsGroup w-[200px] flex flex-col gap-3 ml-2">
                <div className="priceText flex flex-row justify-between mx-1 font-semibold ">
                    <p className='price '>Price</p>
                    {event?.priceRanges ? <p className='price tracking-wide'>${event.priceRanges[0]?.min} - ${event.priceRanges[0]?.max}</p>: <p className='price tracking-wide'>N/A</p>}
                </div>
                <button
                    className='buttonText w-full bg-primary hover:bg-primaryHighlight font-bold items-center px-4 py-2 rounded-lg drop-shadow'
                    onClick={() => window.open(event?.url, "_blank")}
                >GET TICKETS</button>
                <div className="socials flex flex-row justify-between mx-1 font-semibold">
                    {twitterUrl && <img onClick={() => window.open(twitterUrl, "_blank")} src={twitter} className='facebook cursor-pointer' alt=""/>}
                    {facebookUrl && <img onClick={() => window.open(facebookUrl, "_blank")} className='facebook cursor-pointer' src={facebook} alt=""/>}
                    {instagramUrl && <img onClick={() => window.open(instagramUrl, "_blank")} className='instagram cursor-pointer' src={instagram} alt=""/>}
                </div>
            </div>
        </div>
    )
}


const BottomLeft = (props) => {
    const {event} = props;
    // console.log('BottomLeft: event:', event)
    const preSales = event?.sales?.presales || null;
    const info = event?.info || null;
    const pleaseNote = event?.pleaseNote || null
    const description =  event?.description || null;
    const accessibility = event.hasOwnProperty('accessibility') ? (event?.accessibility.hasOwnProperty('info') && JSON.stringify(event?.accessibility?.info).replace(/\\n|\\r/g, "")) : null; //  Object : string.

    const saleStart = event?.sales?.public?.startDateTime || null; // const saleStart = event.hasOwnProperty('sales') ? event.sales.public.startDateTime : null;
    const saleEnd = event?.sales?.public?.endDateTime || null;     // const saleEnd = event.hasOwnProperty('sales') ? event.sales.public.endDateTime : null;
    const onSale = event?.sales?.public?.startTBD || false; // const onSale = event.hasOwnProperty('sales') ? event.sales.public.startTBD : false;
    const saleStatus = event.dates.status.code || null;     // const saleStatus = event.dates.hasOwnProperty('status') ? event.dates.status.code : null;
    const ticketLimit = event?.sales?.public?.ticketLimit || null; // const ticketLimit = event.hasOwnProperty('sales') ? event.sales.public.ticketLimit : null;
    const ageRestrictions =  event?.ageRestriction?.legalAgeEnforced || false; // const ageRestriction = event.hasOwnProperty('ageRestriction') ? event.ageRestriction.legalAgeEnforced : false; //  Object : string.

    const segment = event.hasOwnProperty('classifications') ? event.classifications[0]?.segment?.name : null;
    const genre = event.hasOwnProperty('classifications') ? event.classifications[0]?.genre?.name : null;
    const subGenre = event.hasOwnProperty('classifications') ? event.classifications[0]?.subGenre?.name : null;


    return (
        <div className="bottomLeft w-full flex flex-col gap-6 md:mr-2">
            <div className='heroDescription flex flex-col gap-4 md:mr-6 items-start text-justify md:text-left'>
                {description && <>
                        <h2 className='descriptionTitle font-extrabold font-sans text-xl tracking-wide'>Description</h2>
                        <p className='description text-left'>{description}</p>
                    </>
                }

                <div>{pleaseNote && <><b>Please Note: </b><p>{pleaseNote}</p></>}</div>
                <div>{info && <><b>Info: </b><p>{info}</p></>}</div>
                <p>{accessibility && '\n' + accessibility}</p>

                <div className="flex flex-col gap-4 py-2 cursor-pointer">
                    <Accordion title={"Pre Sales:" } content={
                        <div className="flex flex-col gap-4">
                            {preSales && preSales.map((preSale, index) => {
                                return (
                                    <div key={event.id+index} className="flex flex-col pl-4 pt-4">
                                        <p>{'\n' + preSale.name + ': '}</p>
                                        <p>{'\n' + format(new Date(preSale.startDateTime), 'MMM d, yyyy h:mm a') + ' - ' + format(new Date(preSale.endDateTime), 'MMM d, yyyy h:mm a')}</p>
                                    </div>
                                )})}
                        </div>
                    }/>
                </div>

                <p className='bg-purpleNight w-fit px-6 py-4 my-2 rounded-lg'>{saleStart && "Sales Starts : " + format(new Date(saleStart), 'eee MMM d, yyyy h:mm a')}</p>
                <p className='bg-purpleNight w-fit px-6 py-4 my-2 rounded-lg'>{saleEnd && "Sales End : " + format(new Date(saleEnd), 'eee MMM d, yyyy h:mm a')}</p>

                { onSale ? <p className='bg-purpleNight w-fit px-6 py-2 rounded-lg'>{"OnSale: " + onSale}</p> : null }
                { saleStatus ? <p className='bg-green-400 w-fit px-6 py-2 rounded-lg'>{"Status: " + saleStatus}</p> : null }
                { ticketLimit ? (ticketLimit < 5 ?
                            <p className='bg-purpleNight w-fit px-6 py-2 rounded-lg text-pink-700'>{"Ticket Limit: " + ticketLimit }</p>:
                            <p className='bg-purpleNight w-fit px-6 py-2 rounded-lg text-green-600'>{"Ticket Limit: " + ticketLimit }</p>
                    ) : null }
                { ageRestrictions ? <p className='bg-purpleNight w-fit px-6 py-2 rounded-lg text-pink-700'>{"Age Restriction: " + ageRestrictions }</p> : null }

            </div>
            <div className="dateTimeButtons flex flex-col md:flex-row gap-6 font-semibold items-baseline">
                {(segment && segment !== 'Undefined') && <button className='dateTimeButton hover:bg-highlight bg-purpleNight py-4 px-6 rounded-lg text-left drop-shadow'>
                    <p className='dateText'>{segment}</p>
                </button>}
                {(genre && genre !== 'Undefined') && <button className='dateTimeButton w-fit hover:bg-highlight bg-purpleNight py-4 px-6  rounded-lg text-left drop-shadow '>
                    <p className='dateText'>{genre}</p>
                </button>}
                {(subGenre && subGenre !== 'Undefined') && <button className='dateTimeButton w-fit hover:bg-highlight bg-purpleNight py-4 px-6  rounded-lg text-left drop-shadow'>
                    <p className='dateText'>{subGenre}</p>
                </button>}
            </div>
        </div>
    )
}
